@import './../../../styles/common/variables';
@import 'resources/assets/styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.dhsv_new_side-menu {
  position: fixed;
  z-index: 3604;
  top: 40%;
  right: -10px;
  border-radius: $border-radius;
  background-color: $secondary;
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50px;
  height: 50px;
  transition: all ease 0.3s;
  overflow: hidden;
  padding-right: 21px;

  &:hover:not(.open) {
    padding-right: 11px;
    right: 5px;
  }

  button {
    width: 50px;
    height: 50px;
    color: currentColor;
  }

  .content {
    padding: 30px 15px;
    font-size: 1rem;
    width: 300px;

    ul {
      list-style: none;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex;

          .label {
            text-decoration: underline;
          }

          .material-icons {
            width: 40px;
          }

          &:hover {
            color: $grey-dark;
          }
        }
      }
    }
  }

  &.open {
    width: 300px;
    height: 200px;
  }

  .material-icons {
    font-size: 25px;
  }
}
