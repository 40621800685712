.social-icons {
  justify-content: space-around;
}

.social-icons a {
  margin: 0 5px 10px;
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

.dhsv_side_menu {
  z-index: 10;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(calc(100% - 65px), -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s ease;
  max-height: 65px;
}

@media (max-width: 991.98px) {
  .dhsv_side_menu {
    top: auto;
    bottom: 70px;
    z-index: 11;
    transform: translate(calc(100% - (40px + 1rem)), 0);
  }
}

.dhsv_side_menu .open-menu {
  background-color: #e9511d;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.dhsv_side_menu .open-menu:hover+.side_menu__items {
  transform: translateX(calc(-100% + 65px));
}

@media (max-width: 991.98px) {
  .dhsv_side_menu .open-menu {
    width: 40px;
    height: 40px;
  }

  .dhsv_side_menu .open-menu .material-icons {
    font-size: 18px;
  }
}

.dhsv_side_menu .side_menu__items {
  transform: translateX(calc(100% + 5px));
  transition: transform .4s ease;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #e9511d;
  padding: 1rem;
}

@media (max-width: 991.98px) {
  .dhsv_side_menu .side_menu__items {
    padding: 10px;
  }

  .dhsv_side_menu .side_menu__items .close {
    margin-bottom: 10px;
  }

  .dhsv_side_menu .side_menu__items>a,
  .dhsv_side_menu .side_menu__items i,
  .dhsv_side_menu .side_menu__items div {
    font-size: 14px !important;
  }
}

.dhsv_side_menu .side_menu__items .close {
  color: #fff;
  margin-bottom: 20px;
  display: block;
}

.dhsv_side_menu .side_menu__items .close i {
  cursor: pointer;
}

.dhsv_side_menu .side_menu__items:hover {
  transform: translateX(calc(-100% + 65px));
}

.dhsv_side_menu .side_menu__items>a {
  display: flex;
  padding: 1px;
  margin-bottom: 5px;
  background-color: #e9511d;
  align-items: center;
}

.dhsv_side_menu .side_menu__items>a>div:first-child {
  line-height: 1;
  text-align: center;
  color: #fff;
  font-size: 2rem;
}

.dhsv_side_menu .side_menu__items>a>div:last-child {
  flex: 1;
  line-height: 1.5;
  text-align: left;
  padding: 0 5px;
  color: #fff;
  font-weight: 500;
}

.dhsv_side_menu.active {
  opacity: 1;
  pointer-events: auto;
}

.dhsv_side_menu.active>a {
  pointer-events: auto;
}

