@import './../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.dhsv_side_menu {
  z-index: 10;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(calc(100% - 65px), -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease;
  max-height: 65px;

  @include media-breakpoint-down(md) {
    top: auto;
    bottom: 70px;
    z-index: 11;
    transform: translate(calc(100% - (40px + 1rem)), 0);
  }

  .open-menu {
    background-color: $secondary;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    &:hover {
      & + .side_menu__items {
        transform: translateX(calc(-100% + 65px));
      }
    }

    @include media-breakpoint-down(md) {
      width: 40px;
      height: 40px;

      .material-icons {
        font-size: 18px;
      }
    }
  }

  .side_menu__items {
    transform: translateX(calc(100% + 5px));
    transition: transform 0.4s ease;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: $secondary;
    padding: $space-xs;

    @include media-breakpoint-down(md) {
      padding: 10px;

      .close {
        margin-bottom: 10px;
      }

      > a,
      i,
      div {
        font-size: 14px !important;
      }
    }

    .close {
      color: white;
      margin-bottom: 20px;
      display: block;

      i {
        cursor: pointer;
      }
    }

    &:hover {
      transform: translateX(calc(-100% + 65px));
    }

    > a {
      display: flex;
      padding: 1px;
      margin-bottom: 5px;
      background-color: $secondary;
      align-items: center;

      > div {
        &:first-child {
          line-height: 1;
          text-align: center;
          color: white;
          font-size: 2rem;
        }

        &:last-child {
          flex: 1;
          line-height: 1.5;
          text-align: left;
          padding: 0 5px;
          color: white;
          font-weight: 500;
        }
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;

    > a {
      pointer-events: auto;
    }
  }
}
